import React from "react"
import logo from "./AD14.png"
import { Link } from "gatsby"
import "./style.css"

class Header extends React.Component {
  render() {
    const { location } = this.props;
    const pathname = location.pathname || '';

    const isCurrentPath = (currentPath, pathToCheck) => {
      // check for root
      if (pathToCheck === "/") {
        return currentPath === "/";
      }
      return (currentPath || '').indexOf(pathToCheck) !== -1;
    }

    const blogLinkStyle = {
      paddingBottom: isCurrentPath(pathname, "/blog") ? `5px` : `0px`,
      boxShadow: isCurrentPath(pathname, "/blog") ? `0px 1px 0px 0px black` : `none`,
    }
    const workLinkStyle = {
      paddingBottom: isCurrentPath(pathname, "/work") ? `5px` : `0px`,
      boxShadow:
        isCurrentPath(pathname, "/work") ? `0px 1px 0px 0px black` : `none`,
    }
    const aboutLinkStyle = {
      paddingBottom: isCurrentPath(pathname, "/") ? `5px` : `0px`,
      boxShadow:
        isCurrentPath(pathname, "/") ? `0px 1px 0px 0px black` : `none`,
    }

    return (
      <div className="header-main">
        <Link className="logo-link" to={`/`}>
          <img src={logo} alt="abrahamdio logo" className="logo-img" />
        </Link>

        <div className="header-nav">
          <ul className="nav-ul">
            <li className="nav-li">
              <h1 className="li-h1" style={blogLinkStyle}>
                <Link className="li-link" to={`/blog`}>
                  Blog
                </Link>
              </h1>
            </li>
            <li className="nav-li">
              <h1 className="li-h1" style={workLinkStyle}>
                <Link className="li-link" to={`/work`}>
                  Work
                </Link>
              </h1>
            </li>
            <li
              style={{
                float: "left",
              }}
            >
              <h1 className="li-h1" style={aboutLinkStyle}>
                <Link className="li-link" to={`/`}>
                  About
                </Link>
              </h1>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default Header
